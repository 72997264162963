import React from 'react'
import styled from 'styled-components'

import { NavBar, Section, ScrollIndicator, Footer, SectionHeader } from '@components';

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`

const Spacer = styled.div`
  height: ${props => props.height || 0};
`

const NotFoundPage = () => {

  return (
    <Wrapper>
      <NavBar alwaysDisplay={true} />
      <Spacer height={'50px'} />
      <SectionHeader number={'404'} title={'page not found.'} />
      <div style={{ flex: 1 }}></div>
      <Footer />
    </Wrapper>
  )
}

export default NotFoundPage